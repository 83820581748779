<template>
  <v-dialog
    v-if="isDataLoadedFromServer"
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="850"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Edit Record</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <div class="row pa-0 ma-0">
          <div
            class="col-12 col-sm-6 my-0 py-0 h-60px"
            :class="{ 'col-lg-6 col-md-6': isClient }"
          >
            <v-autocomplete
              v-model="formData.warehouse_id"
              label="Warehouse"
              item-text="text"
              item-value="index"
              :items="serverData.warehouses"
              clearable
              outlined
              dense
              :error-messages="warehouse_idErrors"
              @input="$v.formData.warehouse_id.$touch()"
              @blur="$v.formData.warehouse_id.$touch()"
              @change="onDataChange"
            ></v-autocomplete>
          </div>
          <div class="col-12 col-sm-6 my-0 py-0 h-60px" v-if="!isClient">
            <v-autocomplete
              v-model="formData.customer_id"
              label="Customer"
              item-text="text"
              item-value="index"
              :items="serverData.clients"
              clearable
              outlined
              dense
              :error-messages="customer_idErrors"
              @input="$v.formData.customer_id.$touch()"
              @blur="$v.formData.customer_id.$touch()"
              @change="onDataChange"
            ></v-autocomplete>
          </div>

          <div
            class="col-12 col-sm-6 my-0 py-0 h-60px"
            :class="{ 'col-lg-6 col-md-6': isClient }"
          >
            <v-text-field
              v-model="formData.batch_number"
              label="Batch number"
              clearable
              outlined
              dense
              :error-messages="batch_numberErrors"
              @input="$v.formData.batch_number.$touch()"
              @blur="$v.formData.batch_number.$touch()"
              @change="onDataChange"
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6 my-0 py-0 h-60px">
            <v-text-field
              v-model="formData.location"
              label="Location"
              clearable
              outlined
              dense
              :error-messages="locationErrors"
              @input="$v.formData.location.$touch()"
              @blur="$v.formData.location.$touch()"
              @change="onDataChange"
            ></v-text-field>
          </div>
          <div class="h-75px col-12 col-sm-6 my-0 py-0 h-60px">
            <v-combobox
              :readonly="isSkuFieldEditable"
              v-model="formData.sku_id"
              dense
              label="SKU"
              item-text="systemCode"
              item-value="skuId"
              clearable
              outlined
              :items="items"
              :loading="isLoading"
              :search-input.sync="search"
              hide-no-data
              hide-selected
              :placeholder="
                isClient
                  ? ''
                  : formData.customer_id
                  ? 'Enter SKU'
                  : 'Select customer field!'
              "
              @change="getInventoryBalance"
              :error-messages="sku_idErrors"
              @input="$v.formData.sku_id.$touch()"
              @blur="$v.formData.sku_id.$touch()"
            ></v-combobox>
          </div>

          <div class="col-12 col-sm-6 my-0 py-0 h-60px">
            <v-text-field
              v-model="formData.available_qty"
              label="Available quantity"
              min="0"
              type="number"
              outlined
              dense
              readonly
              :placeholder="
                !(
                  this.formData.warehouse_id &&
                  this.formData.customer_id &&
                  this.formData.location &&
                  this.formData.batch_number &&
                  this.formData.sku_id
                )
                  ? 'Fill all the fields abow'
                  : 'Invalid data'
              "
              :error-messages="available_qtyErrors"
              @input="$v.formData.available_qty.$touch()"
              @blur="$v.formData.available_qty.$touch()"
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6 my-0 py-0 h-60px">
            <v-text-field
              v-model="formData.adjusted_qty"
              label="Adjusted quantity"
              min="1"
              type="number"
              clearable
              outlined
              dense
              :error-messages="adjusted_qtyErrors"
              @input="$v.formData.adjusted_qty.$touch()"
              @blur="$v.formData.adjusted_qty.$touch()"
              @change="
                (value) => {
                  onChangePositiveNumberMaker(value, 'adjusted_qty', 0);
                }
              "
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6 my-0 py-0 h-60px">
            <v-select
              v-model="formData.reason_code"
              label="Reason code"
              item-text="text"
              item-value="index"
              :items="serverData.reason_codes"
              clearable
              outlined
              dense
              :error-messages="reason_codeErrors"
              @input="$v.formData.reason_code.$touch()"
              @blur="$v.formData.reason_code.$touch()"
            ></v-select>
          </div>

          <div class="col-12 my-0 py-0 h-60px">
            <v-text-field
              v-model="formData.comment"
              label="Comment"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>
        </div>
        <!--begin::Actions-->
        <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="this.resetEditForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitEditForm"
          >
            Update
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import { getToken } from "@/core/services/jwt.service";

export default {
  name: "EditItem",
  props: ["refresher", "pageLoader"],
  mixins: [validationMixin],
  components: {},
  validations() {
    return {
      formData: {
        warehouse_id: { required },
        customer_id: {
          required: requiredIf(() => {
            return !this.isClient;
          }),
        },
        location: { required },
        batch_number: { required },
        sku_id: { required },
        inventory_balance_id: { required },
        available_qty: { required },
        adjusted_qty: { required },
        reason_code: { required },
      },
    };
  },
  data: () => ({
    dialog: false,
    entries: [],
    search: null,
    isLoading: false,

    serverData: null,
    formData: {
      id: null,
      warehouse_id: null,
      customer_id: null,
      location: null,
      batch_number: null,
      sku_id: null,

      inventory_balance_id: null,
      available_qty: null,
      adjusted_qty: null,
      reason_code: null,
      comment: null,
    },
    doneTypingInterval: 1500,
    typingTimer: null,
  }),
  methods: {
    onChangePositiveNumberMaker(
      value,
      obj_name,
      min = 0,
      max = -1,
      data_name = "formData"
    ) {
      if (value < min) {
        this[data_name][obj_name] = min;
      } else if (max > 0 && value > max) {
        this[data_name][obj_name] = max;
      }
    },
    onDataChange() {
      this.formData.inventory_balance_id = null;
      this.entries = [];
      this.search = null;
      this.formData.sku_id = null;
      this.formData.available_qty = null;
    },

    loadDataFromServer() {
      this.pageLoader(true);
      const data = { id: this.$store.getters.getItemForAction.id };
      ApiService.post("/warehouse_management/inventory_adjustments/edit", data)
        .then((response) => {
          this.serverData = response.data;
          this.resetEditForm();
        })
        .catch(() => {})
        .finally(() => {
          this.pageLoader(false);
        });
    },
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },

    submitEditForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      let data = {
        ...this.formData,
      };
      data.sku_id = this.formData.sku_id.skuId;
      data.sku_barcode = this.formData.sku_id.systemCode;
      this.pageLoader(true);
      ApiService.post(
        "/warehouse_management/inventory_adjustments/update",
        data
      )
        .then(() => {
          Swal.fire({
            title: "Updated",
            text: `Record data has been updated`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.toggleModal();
          this.refresher();
          this.pageLoader(false);
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetEditForm() {
      if (this.serverData) {
        let copy = { ...this.serverData.adjustment_task };
        Object.entries(this.formData).forEach((localD) => {
          Object.entries(this.serverData.adjustment_task).forEach((serverD) => {
            if (localD[0] === serverD[0]) {
              this.formData[localD[0]] = copy[serverD[0]];
            }
          });
        });
        this.formData.sku_id = {
          skuId: this.serverData.adjustment_task.sku_id,
          systemCode: this.serverData.adjustment_task.sku_barcode,
        };
      } else {
        this.formData = {
          id: null,
          warehouse_id: null,
          customer_id: null,
          location: null,
          batch_number: null,
          sku_id: null,

          inventory_balance_id: null,
          available_qty: null,
          adjusted_qty: null,
          reason_code: null,
          comment: null,
        };
      }
    },
    async getInventoryBalance() {
      if (
        this.formData.warehouse_id &&
        this.formData.customer_id &&
        this.formData.location &&
        this.formData.batch_number &&
        this.formData.sku_id
      ) {
        let data = {
          warehouse_id: this.formData.warehouse_id,
          customer_id: this.formData.customer_id,
          location: this.formData.location,
          batch_number: this.formData.batch_number,
          sku_id: this.formData.sku_id.skuId,
          sku_barcode: this.formData.sku_id.systemCode,
        };
        await ApiService.post(
          "/warehouse_management/inventory_adjustments/get_available_qty",
          data
        )
          .then((response) => {
            this.formData.inventory_balance_id =
              response.data.inventory_balance_id;
            this.formData.available_qty = response.data.available_qty;
          })
          .catch(() => {
            // this.formData.sku_id = null;
          })
          .finally(() => {
            this.pageLoader(false);
          });
      }
    },
    async getData(value) {
      await fetch(`${process.env.VUE_APP_BASE_URL}/skus/search`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({
          q: value,
          client: this.userId,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          this.entries = res.skus;
        })
        .catch()
        .finally(() => (this.isLoading = false));
    },
    toggleModal() {
      if (this.dialog) {
        this.serverData = null;
        this.resetEditForm();
      } else {
        this.loadDataFromServer();
      }
      this.dialog = !this.dialog;
    },
  },
  computed: {
    isSkuFieldEditable() {
      return !this.isClient && !this.formData.customer_id;
    },
    isClient() {
      return this.$store.getters.currentUser.data.is_client;
    },
    userId: function () {
      return this.isClient
        ? this.$store.getters.currentUser.data.id
        : this.formData.customer_id;
    },
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    items: function () {
      return this.entries;
    },
    warehouse_idErrors: function () {
      return this.handleFormValidation("warehouse_id", this);
    },
    customer_idErrors: function () {
      return this.handleFormValidation("customer_id", this);
    },
    locationErrors: function () {
      return this.handleFormValidation("location", this);
    },
    batch_numberErrors: function () {
      return this.handleFormValidation("batch_number", this);
    },
    sku_idErrors: function () {
      return this.handleFormValidation("sku_id", this);
    },

    inventory_balance_idErrors: function () {
      return this.handleFormValidation("inventory_balance_id", this);
    },
    available_qtyErrors: function () {
      return this.handleFormValidation("available_qty", this);
    },
    adjusted_qtyErrors: function () {
      return this.handleFormValidation("adjusted_qty", this);
    },
    reason_codeErrors: function () {
      return this.handleFormValidation("reason_code", this);
    },
  },
  watch: {
    search(val) {
      clearTimeout(this.typingTimer);
      if (!val || val == "") {
        return;
      }
      this.typingTimer = setTimeout(() => {
        this.isLoading = true;
        this.getData(val.trim());
      }, this.doneTypingInterval);
    },
  },
};
</script>
